import { CompanyHierarchyTree, getCompanyDepth } from 'components/common/parent-child-tree'
import { logout } from 'components/common/logout'
import { AvatarImage } from 'components/common/avatar-images'
import { Dropdown } from 'components/common/dropdown'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import Style from 'style'
import UsersState from 'state/users'
import cx from 'classnames'
import { getOrigin } from 'utilities/http'
import { resolve } from 'utilities/deprecated-named-routes'
import { isAndroid, isIPhone, isPWA } from 'utilities/browser'
import { t } from 'i18n'
import goToApp from './goToApp'

@Radium
export class AvatarInfo extends React.Component {
  render() {
    const { currentUser } = this.props
    const { company } = currentUser
    return (
      <button
        type="button"
        key="avatarMenuContainer"
        className="tw-flex tw-py-1 tw-h-11 tw-w-11 lg:tw-w-auto tw-px-1 lg:tw-px-2 tw-rounded-md hover:tw-bg-gray-100"
      >
        <AvatarImage
          user={this.props.currentUser}
          size="2.5"
          style={styles.profileCircle}
          className="tw-bg-gray-100 tw-rounded-full tw-overflow-hidden tw-select-none tw-w-9 tw-h-9"
        />
        <div className="tw-self-center tw-ml-2 tw-text-left tw-hidden lg:tw-block">
          <h5 key="primary" className="tw-font-medium tw-text-gray-700 tw-my-0">
            {this.props.currentUser.first_name}
          </h5>
          <p key="secondary" className="tw-text-xs tw-text-gray-500">
            {company && company.name}
          </p>
        </div>
      </button>
    )
  }
}

export class AvatarMenu extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    displayTempPositiveMessage: PropTypes.func.isRequired,
  }

  createCompanyPickerItem = (company) => {
    const depth = getCompanyDepth(this.props.companies, company)
    const dashes = '-'.repeat(depth)
    // Show a checkmark to indicate parent company.
    const isCurrentLearnerCompany = this.props.currentUser.company.id === company.id
    // Show a dash to indicate child company.
    const hasParentCompany = Boolean(company.parent_company)
    const classes = cx('ui', 'item', { active: isCurrentLearnerCompany })
    return (
      <a
        className={classes}
        onClick={(event) => {
          event.stopPropagation()
          this.changeCompany(company)
        }}
        key={company.id}
      >
        {hasParentCompany ? `${dashes} ` : null}
        {company.name}
        {/* {isCurrentLearnerCompany && (
          <i style={styles.avatarIcon} className="ui icon checkmark" />
        )} */}
      </a>
    )
  }

  changeCompany = (company) => {
    UsersState.ActionCreators.doDetailAction(
      this.props.currentUser.id,
      'change_user_to_new_company',
      {
        company_url: company.url,
      }
    ).then(() => (window.location.href = `${getOrigin()}/views/home/`))

    this.context.displayTempPositiveMessage({
      heading: 'Changing company...',
      body: 'It may take a minute before changes take effect.',
    })
  }

  openShortcut = () => {
    analytics.track('Installed PWA Desktop App')
    // deferredPrompt is set in webpack-django.html via django-pwa
    try {
      window.deferredPrompt.prompt()
    } catch (err) {
      alert(`😕 ${t('cannot_install_pwa')}`) // eslint-disable-line no-alert
    }
  }

  render() {
    const { learner, company } = this.props.currentUser
    const displayCompanyPicker =
      this.props.companies &&
      this.props.companies.length > 1 &&
      // This aims to prevent users who accidentally joined a parent company
      // from being able to join any child companies.
      company.subscription.parent_child_companies_enabled &&
      learner.is_parent_company_admin

    const pwaAvailable = !isPWA() && !!window.deferredPrompt

    const items = [
      {
        element: (
          <Link
            className="item"
            to={resolve('profile', { userId: this.props.currentUser.id })}
            key="profile"
          >
            {t('profile')}
          </Link>
        ),
        show: true,
      },
      {
        element: (
          <a
            className="ui item"
            href="http://help.myagi.com/"
            rel="noopener noreferrer"
            target="_blank"
            key="help"
          >
            {t('help_center')}
          </a>
        ),
        show: true,
      },
      {
        element: (
          <a className="ui item" onClick={this.openShortcut} key="pwa" style={styles.link}>
            {t('install_desktop_app')}
          </a>
        ),
        show:
          window.chrome &&
          !(isAndroid() || isIPhone()) &&
          (learner.is_internal_learner || learner.is_myagi_staff) &&
          pwaAvailable,
      },
      {
        element: (
          <a className="ui item" onClick={goToApp} key="app">
            {t('get_the_app')}
          </a>
        ),
        show: isAndroid() || isIPhone(),
      },
      {
        element: (
          <Link className="item" to={resolve('settings')} key="settings">
            {t('settings')}
          </Link>
        ),
        show: true,
      },
      {
        element: (
          <a className="ui item" href="#" onClick={logout} key="logout">
            {t('logout')}
          </a>
        ),
        show: true,
      },
    ]

    return (
      <Dropdown className="ui top right pointing dropdown" style={styles.dropdown}>
        <AvatarInfo currentUser={this.props.currentUser} />

        <div className="menu" style={styles.profilePicMenu}>
          {displayCompanyPicker && (
            <React.Fragment>
              <div className="ui item" style={styles.changeCompany}>
                {t('change_company')}
              </div>
              <CompanyHierarchyTree
                companies={this.props.companies}
                currentUser={this.props.currentUser}
                createCompanyItem={this.createCompanyPickerItem}
              />
              <div style={styles.companyPickerSeparator} />
            </React.Fragment>
          )}
          {items.map((item) => item.show && item.element)}
        </div>
      </Dropdown>
    )
  }
}

const styles = {
  avatarImgContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    color: Style.vars.deprecatedColors.textBlack,
  },
  companyPickerSeparator: {
    borderTop: `1px solid ${Style.vars.deprecatedColors.grey}`,
  },
  dropdown: {
    zIndex: 99,
  },
  profileCircle: {
    marginBottom: '0',
    marginRight: '0',
    width: '36px',
    height: '36px',
  },
  profilePicMenu: {
    marginTop: 11,
    maxHeight: 500,
    overflowX: 'hidden',
  },
  searchMobile: {
    container: {
      width: '90vw',
      margin: '0 auto 20px auto',
    },
  },
  avatarImgTextContainer: {
    marginLeft: 10,
    [Style.vars.media.mobile]: {
      display: 'none',
    },
  },
  avatarIcon: {
    marginLeft: 5,
  },
  changeCompany: {
    background: Style.vars.deprecatedColors.grey,
    cursor: 'default',
    zIndex: 3,
    marginRight: -1,
    marginLeft: -1,
    marginTop: -1,
    color: Style.vars.deprecatedColors.primaryFontColor,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    textAlign: 'center',
  },
  desktopRightSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    background: 'rgb(250, 115, 70)',
    color: 'white',
  },
}
