import { ButtonToggle, Form, SubmitButton } from 'components/common/form-deprecated/index'

import $y from 'utilities/yaler'
import ChannelsState from 'state/channels'
import Marty from 'marty'
import { MultiSelect } from 'components/common/form-deprecated/select'
import PropTypes from 'prop-types'
import React from 'react'
import { StaticModal } from 'components/common/modals/static'
import TrainingPlanTrainingUnitsState from 'state/training-plan-training-units'
import TrainingPlansState from 'state/training-plans'
import _ from 'lodash'
import containerUtils from 'utilities/containers'
import { t } from 'i18n'
import moment from 'moment-timezone'

const INTERNAL = 'Internal'
const EXTERNAL = 'External'

const styles = {
  toggleContainer: {
    marginBottom: 20,
  },
}

const TrainingPlanOptionRenderer = (option) => (
  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
    <div>{option.label}</div>
    <div>{`${t('last_updated')}: ${option.time_label}`}</div>
  </div>
)

export class AddTrainingPlansForm extends React.Component {
  static data = {
    trainingPlan: {
      required: true,
      fields: ['id', 'name', 'url', 'owner.id', 'owner.name', 'modified'],
    },
  }

  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    routeParams: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    let plans = this.props.selectedTrainingPlans ? this.props.selectedTrainingPlans : []
    plans = plans.map((plan) => ({
      label: plan.name,
      value: plan.url,
      time_label: moment(plan.modified).format('DD MMM YYYY'),
    }))
    this.state = {
      selectedTrainingPlans: plans,
      planType: INTERNAL,
    }
  }

  onSubmitAndValid = (data) => {
    const promises = data.training_plans.map((tpURL) =>
      TrainingPlanTrainingUnitsState.ActionCreators.create({
        training_unit: `http://localhost:8000/api/v1/training_units/${this.context.routeParams.channelId}/`,
        training_plan: tpURL,
        order: 0,
      })
    )

    Promise.all(promises)
      .then(() => {
        this.context.displayTempPositiveMessage({
          heading: t('plans_added_to_channel'),
        })
      })
      .catch(() => {
        this.context.displayTempNegativeMessage({
          heading: t('unable_to_add_plans_to_channel_error'),
        })
      })
    TrainingPlansState.Store.resetState()
    this.context.router.push(
      `/views/edit-content/channel/${this.context.routeParams.channelId}/plans/`
    )
  }

  filterPlansByInternalvsExternalCriteria(plans) {
    const company = this.context.currentUser.company
    let filteredPlans = []
    filteredPlans = plans.filter((tp) => {
      if (this.state.planType === EXTERNAL) {
        return tp.owner.id !== company.id
      }
      return tp.owner.id === company.id
    })
    return filteredPlans
  }

  mapPlansToOptions(plans) {
    return _.chain(plans)
      .sortBy('modified')
      .reverse()
      .map((tp) => ({
        value: tp.url,
        label: this.state.planType === INTERNAL ? tp.name : `${tp.owner.name} - ${tp.name}`,
        time_label: moment(tp.modified).format('DD MMM YYYY'),
      }))
      .sortBy('label')
      .value()
  }

  render() {
    let options = []
    if (this.props.trainingPlans) {
      const filteredPlans = this.filterPlansByInternalvsExternalCriteria(this.props.trainingPlans)
      options = this.mapPlansToOptions(filteredPlans)
    }

    const initPlanSelection = this.state.selectedTrainingPlans.map((plan) => ({
      label: plan.label,
      value: plan.value,
    }))

    return (
      <StaticModal header={t('add')} {...this.props}>
        <Form
          onSubmitAndValid={this.onSubmitAndValid}
          loading={this.props.trainingPlans === undefined}
        >
          <h3>{t('select_plans')}</h3>
          <div style={styles.toggleContainer}>
            <ButtonToggle
              leftLabel={INTERNAL}
              rightLabel={EXTERNAL}
              initialValue={INTERNAL}
              onChange={(planType) =>
                this.setState((previousState) => ({ ...previousState, planType }))
              }
            />
          </div>
          <MultiSelect
            key={this.state.planType}
            required
            name="training_plans"
            options={options}
            optionRenderer={TrainingPlanOptionRenderer}
            initialSelection={initPlanSelection}
            placeholder={`${t('select_plans')}...`}
            noResultsText={`${t('no_plans_found')}.`}
            onChange={_.noop}
            multi
          />
          <SubmitButton loading={this.props.loading} />
        </Form>
      </StaticModal>
    )
  }
}

export const AddExistingPlanModal = Marty.createContainer(AddTrainingPlansForm, {
  contextTypes: {
    routeParams: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
  },

  listenTo: [ChannelsState.Store, TrainingPlansState.Store],

  fetch: {
    trainingPlans() {
      const query = {
        can_add_to_channel: this.context.routeParams.channelId,
        deactivated__isnull: true,
        ordering: 'owner__name,name',
        fields: [$y.getFields(AddTrainingPlansForm, 'trainingPlan')],
        limit: 1500,
      }

      return TrainingPlansState.Store.getItems(query, {
        // Wait for channel updates before refetching data
        dependantOn: ChannelsState.Store,
      })
    },
  },
  pending() {
    return containerUtils.defaultPending(this, AddTrainingPlansForm)
  },
  failed(errors) {
    return containerUtils.defaultFailed(this, AddTrainingPlansForm, errors)
  },
})
